<template>
  <div class="section-wrapper">
    <div class="sub-section-wrapper">
      <breadcrumbs :content="breadcrumbsContents"></breadcrumbs>
      <div class="wrapper-box">
        <div class="main-content-wrapper">
          <div class="content-title">
            {{ currentPosition.name }}
          </div>
          <div class="content-tips">
              <ul>
                  <li>
              <span>{{ currentPosition.jobType }}</span>
            </li>
            <li>
              <Icon type="ios-people-outline" />
              <span class="amount">{{ currentPosition.amount }}人</span>
            </li>
            <li>
                <span>
                    <Icon type="ios-snow-outline" />
                    {{currentPosition.category}}
                </span>
              
            </li>
            <li>
              <span class="amount">发布于：{{ currentPosition.pubdate }}</span>
            </li>
              </ul>
            
          </div>
          <div class="content-box">
            <div class="c-html" v-html="currentPosition.content"></div>
          </div>
          <div class="footer">
              <span class="way-bymail">
                  简历请发送至：
                  <a href="mailto:hr@chinasrcc.com">hr@chinasrcc.com</a>
              </span>
          </div>
        </div>
        <div class="relative-content-wrapper">
          <div class="relative-box">
            <div class="relative-title">
              <p>相关职位</p>
            </div>
            <div
              class="ele-r"
              v-for="(item, index) in relativePosition"
              :key="index"
            >
              <div class="r-title">
                <p @click="linkTo('/job_description/' + item.id)">{{ item.name }}</p>
              </div>
              <div class="relative-tips">
              <ul >
                  <li>
              <span>{{ item.jobType }}</span>
            </li>
            <li>
              <span class="amount">{{ item.amount }}人</span>
            </li>
            <li>
                <span>
                    {{item.category}}
                </span>
              
            </li>
            <li>
              <span class="amount">{{ item.pubdate }}</span>
            </li>
              </ul>
            
          </div>
            </div>
          </div>
        </div>
      </div>

      <Spin v-if="spin" fix size="large"></Spin>
    </div>
  </div>
</template>
<script>
import breadcrumbs from "@/components/Libs/Breadcrumbs.vue";
export default {
  name: "JobDescription",
  components: {
    breadcrumbs,
  },
  props: {
    positionId: {
      type: String,
      required: true,
      default: "1",
    },
  },
  data() {
    return {
      currentPosition: {
        id: 1,
        name: "力学仿真助理工程师",
        content:
          "<p>岗位职责：</p><p>1、负责轨道交通车辆车体、转向架结构疲劳强度分析；</p><p>2、负责轨道交通车辆车体、转向架振动模态分析；</p><p>3、负责局部结构裂纹尖端应力分析；</p><p>4、负责轨道车辆轮轨动力学仿真分析；</p><p>5、根据项目要求完成各项任务。</p><p>任职资格：</p><p>1、学历经验：力学、轨道车辆、航空航天专业优先；焊接、机械设计、金属材料其次；</p><p>2、专业素质：仿真软件操作能力，熟练掌握 ANSYS/ABAQUS/ADAMS/HYPERMESH等仿真软件之一。</p>",
        amount: 10,
        category: "技术类",
        jobType:'全职',
        pubdate: "2022-01-05",
      },
      relativePosition: [
        {
          id: 1,
          name: "检验检测工程师（电气）",
          amount: 10,
        category: "技术类",
        jobType:'全职',
        pubdate: "2022-01-05",
        },
        {
          id: 2,
          name: "检验检测工程师（轮轨动力学、弓网测试、轨道动态几何测试）",
          amount: 10,
        category: "技术类",
        jobType:'全职',
        pubdate: "2022-01-05",
        },
        {
          id: 3,
          name: "ECA 评估技术员（高新方向）",
          amount: 10,
        category: "技术类",
        jobType:'全职',
        pubdate: "2022-01-05",
        },
        {
          id: 4,
          name: "现场检测员",
          amount: 10,
        category: "技术类",
        jobType:'全职',
        pubdate: "2022-01-05",
        },
        {
          id: 4,
          name: "材料检测员/工程师",
          amount: 10,
        category: "技术类",
        jobType:'全职',
        pubdate: "2022-01-05",
        },
      ],
      spin: false,
    };
  },
  created(){
    this.getData();
  },
  methods: {
    getData() {
      this.spin = true;
      this.$axios
        .post(this.$url + '/content', {
          jobId: this.positionId,
          askData:'jobs',
        })
        .then(
          response => {
            this.currentPosition = response.data.current;
            this.relativePosition = response.data.relative;
            this.spin = false;
          }
        );
    },
    linkTo: function (value) {
      this.spin = true;
      location.href = value;
    },
  },
  computed: {
    breadcrumbsContents: function () {
      return [
        { text: "首页", url: "/" },
        { text: "职位列表", url: "/recruiting_list" },
        { text: "职位详情（"+this.currentPosition.name+"）", url: "/job_description/" + this.currentPosition.id },
      ];
    },
  },
  mounted() {
  },
};
</script>
<style scoped>
.section-wrapper {
  width: 100%;
  padding-bottom:70px;
}

.sub-section-wrapper {
  width: 1280px;
  margin: 0 auto;
  overflow: hidden;
  position: relative;
}
.wrapper-box {
  display: -webkit-flex;
  display: flex;
  display: -ms-flexbox;
  justify-content: space-between;
  padding: 0 15px;
}

.footer {
  display: -webkit-flex;
  display: flex;
  justify-content: space-between;
}

.main-content-wrapper {
  width: 65%;
}

.relative-content-wrapper {
  width: 28%;
}

.content-title {
  color: #000;
  font-size: 28px;
  font-weight: 700;
  line-height: 1.6;
  letter-spacing: normal;
  margin: 0 0 20px 0;
}
.content-box {
    border-top:1px solid #ddd;
  padding: 40px 0 40px;
  font-size: 16px;
  text-align: justify;
  line-height: 2;
  color: #000000;
  font-family: Roboto, "Helvetica Neue", Arial, Helvetica, "PingFang SC",
    "Hiragino Sans GB", "Heiti SC", "Microsoft YaHei", "WenQuanYi Micro Hei",
    sans-serif;
}

.content-tips {
  text-align: left;
  margin-left: -15px;
  font-size: 13px;
  color:#999;
  padding-bottom:40px;
}

.content-tips li {
  list-style: none;
  display: inline;
  padding: 0px 15px;
  border-right: 1px solid #eee;
}

.content-tips li:hover {
  color: #b70005;
}

.content-tips li:last-child {
  border-right: none;
}

.page-wrapper {
  text-align: center;
  padding: 50px 0;
}


.relative-content-wrapper {
  background: #f3f7fa;
  padding: 40px;
}

.relative-title {
  font-size: 18px;
  margin-bottom: 25px;
  color: #000000;
}

.ele-r {
  padding: 0px 0 20px 0;
  border-bottom: 1px solid #ddd;
}

.ele-r:last-child {
  border-bottom: none;
}

.r-title p {
  font-size: 15px;
  color: #111;
  margin-bottom: 16px;
  margin-top: 26px;
  cursor: pointer;
  display: block;
}

.r-title p:hover {
  color: #00508e;
}

.relative-tips {
  text-align: left;
  margin-left: -10px;
  font-size: 11px;
  color:#999;
}

.relative-tips li {
  list-style: none;
  display: inline;
  padding: 0px 10px;
  border-right: 1px solid #eee;
}

.relative-tips li:last-child{
    border-right: none;
}

.footer{
    border-top:2px solid #00508e;
    padding: 15px 2px;
}

.way-bymail{
    font-size: 16px;
    color:#00508e;
    font-weight: 600;
}
</style>