<template>
<div class="outer-wrapper">
   <p>
     <span v-for="(item,index) in content" :key="index" @click="linkTo(item.url)" :style="index===content.length-1?{color:'#333'}:{}">
       {{item.text}}
       <span v-show="index!==content.length-1">></span>
     </span>
   </p>
</div>
 
</template>
<script>
export default {
  name: "sectionTitle",
  props: {
    content: {
      type: Array,
      required: true,
      default(){
        return [{text:'首页',url:'/'},];
      }
    },
  },
  data() {
    return {
    };
  },
  methods: {
    linkTo: function (value) {
      location.href = value;
    },
  },
  mounted() {},
};
</script>
<style scoped>

.outer-wrapper{
  width:100%;
  padding: 50px 0 50px;
  text-align: left;
}

.outer-wrapper span{
  color:#999;
  cursor: pointer;
  font-size: 15px;
  font-family: 微软雅黑;
}

</style>